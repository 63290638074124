/**
 *
 * Custom function
 *
 */ 

(function($) {

	function scrollAnimations() {
		
		var header = $('#js-header'),
			menu = $('#js-fixed-menu'),
			headerH = $('body').is('.is-mobile') ? header.outerHeight() : 0, // Height compreso di margini e bordi
			menuH = menu.innerHeight(), // Height senza margini e bordi
			scrollValue = headerH - menuH,
			booking = $('#booking-adv'),
			posBooking = booking.position();

		if ( $('body').is('.is-mobile') ) {
			header.css('height', '');
		}

		if ( $('body').hasClass("no-slideshow") ) {
			booking.addClass("to-bottom");
		}
		
		$(window).scroll(function() {
			scrolltop = $(window).scrollTop();

			if ( scrolltop >= scrollValue) {
				if ( $('body').is('.is-mobile') ) {
					menu.addClass("scroll");
					$('#page').css( 'margin-top', menu.outerHeight() );
				} else {
					header.addClass("scroll");
					$('#page').css( 'margin-top', header.outerHeight() );
				}
			} else {
				if ( $('body').is('.is-mobile') ) {
					menu.removeClass("scroll");
				} else {
					header.removeClass("scroll");
				}
			}

			if ( !$('body').is('.is-mobile') && booking.length ) {
				if ( scrolltop >= posBooking.top ) {
					booking.addClass("to-bottom");
				} else {
					if ( !$('body').hasClass("no-slideshow") ) {
						booking.removeClass("to-bottom");
					}
				}
				
				if ( scrolltop + $(window).height() == $(document).height() ) {
					booking.addClass("no-display");
				} else {
					booking.removeClass("no-display");
				}
			}
		});

	}

	/*
	 * Funzione per utilizzare MixItUp da select
	 */

	function filterElements() {
		
		var filterSelect = '.select-offers',
			container = '.mix-filter-support';

		if ( $(container).length ) {
			var mixer = mixitup(container);

			$(filterSelect).on('change', function() {
				mixer.filter(this.value);
			});
		}

	}

	/* Funzione per effetto bounce */
	$.fn.exBounce = function() {
		
		var self = this;
		(function runEffect() {
			self.effect("bounce", { direction: 'right', times: 1, distance: 10 }, 1000, runEffect);
		})();
		return this;

	};

	$(document).ready(function() {

		scrollAnimations();
		
		if ( $('body').is('.is-mobile') ) {
			filterElements();
		}

		$('.gift').exBounce();
		$('.gift').click(function(e) {
			e.preventDefault();
			$('.booking-advantages').toggle();
		});

		$('#feedback .item .description').collapser({
			mode: 'lines',
			truncate: 5,
			showText: '[ + ]',
			hideText: '[ - ]',
			controlBtn: 'opener',
		});
		
	});


	$(window).load(function() {

		// Altezze uguali per blocchi descrittivi
		$('#offerts .item .data').equalHeights();
		$('#offers-side .item .data').equalHeights();
		$('.offert-list .item .data').equalHeights();

	});

	$(window).resize(function() {


	});

})(jQuery);
